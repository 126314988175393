import React, {useEffect, useMemo, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {generatePath, useNavigate} from 'react-router';

import {Trans, useTranslation} from "react-i18next";
import {logout} from "../api";
const LeftMenu = props =>{
    const { pathname, } = useLocation()
    const {  language,   } = useParams();
    const [currentLanguage, setCurrentLanguage] = useState('en');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('container')
  //  const params =  matchPath(pathname, { path:"/:language" })
//    console.log({language, pathname})
   // const router = useRouter();
    useEffect(()=>{
        if(language&&language.length===2){
            changeLanguage(language);
            setCurrentLanguage(language)
        }else {
            changeLanguage('en')
        }

    },[])
    const isActive= link =>{
        let has = pathname===link;
        if(!has){
            has = pathname.indexOf(link)>-1;
        }
        return (has===true)?'width-icn active':'width-icn'
    }
    const onLogout = () =>{
        logout().finally(r => {
            window.location = "/account"
        });

    }
    function changeLanguage(lan) {
        let pathArray = pathname.split('/');
        if(pathArray.length===0){
            return
        }
        if(pathArray[0].length===0){
            pathArray.shift();
        }
        if(pathArray[0].length===2){
            pathArray[0]=lan;
        }else{
            pathArray.unshift(lan);
        }
        pathArray.unshift('')
        const path = pathArray.join('/');
        navigate(path);
        setCurrentLanguage(lan);
        i18n.changeLanguage(lan);
    }
    useEffect(()=>{
        let toggleBtns = document.querySelectorAll('.toggle-menu');
        let menu = document.querySelector('.dashboard-menu'); // Using a class instead, see note below.
        let humb = document.querySelector('.humb'); // Using a class instead, see note below.
        humb.onclick = function () {
            menu.classList.toggle('active');
            humb.classList.toggle('active');
        };
    },[])
    const doclink = useMemo(() => {
        if(currentLanguage==='ru'){
            return "https://docsru.coronium.io"
        }
        if(currentLanguage==='cn'){
            return "https://docscn.coronium.io"
        }
        return "https://docs.coronium.io";
    }, [currentLanguage]);
    return(
        <div className="dashboard-menu color-dark">
            <div className="m-blocks">
                <div className="m-block border-bottom bd-gray">
                    <div className="tit fw-bold">{t('PROXY_MANAGEMENT')}</div>
                    <div className="links">
                        <Link to={`/${language}/servers`} className={isActive('/servers')}>
                            <div className="icon">
                                <img src="/assets/images/m3.svg" alt="icon"/>
                            </div>
                            <p>{t('Servers')}</p>
                        </Link>
                            {/*<Link to={`/${language}/modems`} className={isActive('/modems')}>
                                <div className="icon">
                                    <img src="/assets/images/m1.svg" alt="icon"/>
                                </div>
                                <p>{t('Modems')}</p>
                            </Link>*/}


                    </div>
                </div>
                <div className="m-block border-bottom bd-gray">
                    <div className="tit fw-bold">{t('ACCOUNT')}</div>
                    <div className="links">
                        <Link to={`/${language}/account`} className={isActive('/account')}>
                            <div className="icon">
                                <img src="/assets/images/m4.svg" alt="icon"/>
                            </div>
                            <p>{t('Your_Account')}</p>
                        </Link>
                        <Link to={`/${language}/billings`} className={isActive('/billings')}>
                            <div className="icon">
                                <img src="/assets/images/m4.svg" alt="icon"/>
                            </div>
                            <p>{t('Billings')}</p>
                        </Link>
                    </div>
                </div>

                <div className="m-block">
                    <div className="tit fw-bold">{t('KNOWLEDGE_DESK')}</div>
                    <div className="links">
                        <a href={doclink} target="_blank">
                            <div className="icon">
                                <img src={"/assets/images/m4.svg"} alt="icon"/>
                            </div>
                            <p>{t('Documentation')}</p>
                        </a>
                        <a onClick={e=>{
                            if(window.tidioChatApi){
                                window.tidioChatApi.open();
                            }
                        }} style={{cursor:'pointer'}}>
                            <div className="icon">
                                <img src="/assets/images/m4.svg" alt="icon"/>
                            </div>
                            <p>{t('Contact_Support')}</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className="menu-bottom">
                <div className="languages border-top bd-gray">
                    <div className="tit">{t('LANGUAGE')}</div>
                    <div className="links">
                        <div className="link">
                            <a onClick={e=>changeLanguage('ru')}>Русский</a>
                        </div>
                        <div className="link">
                            <a onClick={e=>changeLanguage('en')}>English</a>
                        </div>
                        <div className="link">
                            <a   onClick={e=>changeLanguage('cn')}>中国人</a>
                        </div>
                    </div>
                </div>
                <div className="link logout-link border-top bd-gray">
                    <a onClick={e=>onLogout()}>{t('logout')}</a>
                </div>
            </div>
        </div>

        /*<div className="col-auto bar-col">
            <div className="row no-gutters">
                <header className="header-desc col-12">
                    <Link to={'/'} className="logo">
                        <img src="/assets/images/logo.svg" alt="Coronium"/>
                    </Link>
                </header>
                <nav className="main-nav col-12">
                    <div className="delimiter mb-3">PROXY MANAGEMENT</div>
                    <Link to={`/${language}/proxies`} className={isActive('/proxies')}>
                        <img src="/assets/images/server.svg" alt=""/> {t('Proxies')}
                    </Link>
                    <Link to={`/${language}/add-proxy`} className={isActive('/add-proxy')}>
                        <img src="/assets/images/addproxie.svg" alt=""/> {t('Add Proxy')}
                    </Link>
                    <Link to={`/${language}/billing-renewals`} className={isActive('/billing-renewals')}>
                        <img src="/assets/images/billing.svg" alt=""/> {t('Billing & Renewals')}
                    </Link>
                    <div className="delimiter mt-5 mb-3">ACCOUNT</div>
                    <Link to={`/${language}/account`} className={isActive('/active')}>
                        <img src="/assets/images/user.svg" alt=""/> {t('Account')}
                    </Link>{/!*
                    <Link to="/pending-payments" className={isActive('/pending-payments')}>
                        <img src="/assets/images/user.svg" alt=""/> Pending payments
                    </Link>*!/}
                    <Link to={`/${language}/logout`} className="width-icn d-flex d-md-none">
                        <img src="/assets/images/logout.svg" alt=""/> Log Out
                    </Link>

                </nav>
                <button onClick={e=>changeLanguage('en')}>{t('English')}</button>
                <button onClick={e=>changeLanguage('ru')}>{t('Russian')}</button>
                <button style={{position:"absolute",bottom:0, marginLeft:40,marginBottom:20,fontSize:12}} onClick={onLogout} className="btn btn-link cl-white text-white">Log Out</button>
            </div>
        </div>*/
    )
}

export default LeftMenu;