import {endpoint} from "./index";
import {fetch_options} from "./header";




export function getServersAPI() {
    return fetch(endpoint+'/servers',fetch_options).then(r=>r.json());
}

export function addServerAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/servers`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateServerAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/servers/${_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}

export function deleteServerAPI(_id){
    return fetch(`${endpoint}/servers/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}

export function syncServerPortsAPI(serverId){
    return fetch(`${endpoint}/servers/${serverId}/sync-ports`,{...fetch_options, method:'POST'}).then(r=>r.json())
}
