import "isomorphic-fetch";
export const endpoint = window.location.origin + "/api/v1";

const fetch_options = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
};
export * from "./server-api";

export function getAccountAPI() {
    return fetch(`${endpoint}/account`,fetch_options).then(r=>r.json());
}

export function signContractAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/account/sign-contract`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function getCountriesAPI() {
  return fetch(endpoint+'/countries',fetch_options).then(r=>r.json());
}
export function getTariffs() {
  return fetch(endpoint+'/tariffs',fetch_options).then(r=>r.json());
}
export function getModemsAPI(server_id) {
  return fetch(endpoint+'/enterprise/modems',fetch_options).then(r=>r.json());
}
export function detectModemsAPI(server_name) {
  return fetch(endpoint+`/servers/${server_name}/modems/detect`,fetch_options).then(r=>r.json());
}
export function restartModemAPI(_id){
    return fetch(`${endpoint}/enterprise/modems/${_id}/restart`,{...fetch_options,method:'POST'}, ).then(r=>r.json())
}
export function deleteModemAPI(_id){
    return fetch(`${endpoint}/modems/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}

export function addModemAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/enterprise/modems`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateModemAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/enterprise/modems/${_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function getModemCountAPI({country_id, carrier_id,region_id}) {
  const carrier = carrier_id?`?carrier_id=${carrier_id}`:'';
  let region = region_id?`region_id=${region_id}`:'';
  region = carrier?'&'+region:region;
  return fetch(endpoint+`/modems/count/${country_id}`+carrier+region,fetch_options).then(r=>r.json());
}
export function getSettingsAPI() {
    return fetch(endpoint+'/settings',fetch_options).then(r=>r.json());
}
export function getNotificationsAPI() {
    return fetch(`${endpoint}/notifications`,fetch_options).then(r=>r.json());
}
export function getNotificationAPI(_id) {
    return fetch(`${endpoint}/notifications/${_id}`,fetch_options).then(r=>r.json());
}
export function restartModemById(_id){
    return fetch(`${endpoint}/modems/${_id}/restart`,{...fetch_options,method:'POST'}, ).then(r=>r.json())
}
export function setRotationIntervalAPI(_id,rotation_interval){
    let body = JSON.stringify({rotation_interval});
    return fetch(`${endpoint}/modems/${_id}/set-rotation-interval`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function setWhiteListAPI(_id,white_list){
    let body = JSON.stringify({white_list});
    return fetch(`${endpoint}/modems/${_id}/set-white-list`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function getPaymentsAPI() {
  return fetch(endpoint+'/payments').then(r=>r.json());
}
export function getPendingPaymentActiveAPI() {
  return fetch(endpoint+'/pending-crypto-payment-active').then(r=>r.json());
}
export function getPendingPaymentsAPI() {
  return fetch(endpoint+'/pending-payments').then(r=>r.json());
}
export function getCryptoPendingPaymentsAPI() {
  return fetch(endpoint+'/pending-crypto-payments').then(r=>r.json());
}
export function cancelPendingPaymentAPI(_id) {
    return fetch(`${endpoint}/pending-payments/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}


export async function checkCredentials(login, password, code){
    try{
        const result = await fetch(
            `${endpoint}/check-credentials`,
            {
                ...fetch_options,
                body:JSON.stringify({login,password, code})
                ,method:'POST'
            });
        const json = await result.json()
        json.status = result.status;
        return Promise.resolve(json);
    }catch (err){
        return Promise.reject(err);
    }
  return //.then(r=>r.json());
};

export function getInvoicesAPI() {
    return fetch(endpoint+'/invoices').then(r=>r.json());
}
export function logout() {
    return fetch(endpoint+'/logout').then(r=>r.json());
}
export function ping() {
    return fetch(endpoint+'/ping').then(r=>r.json());
}

export function verifyReCaptchaAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/verify-re-captcha`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function checkLoginAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/check-login`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function signUpAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/signup`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function sendResetPasswordTokenAPI({login,language}){
    let body = JSON.stringify({login,language});
    return fetch(`${endpoint}/send-reset-password-token-to-email`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function sendConfirmationEmailLinkAPI({login,language='en'}){
    let body = JSON.stringify({login,language});
    return fetch(`${endpoint}/send-confirmation-email-link`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function changePasswordAPI({token,password}){
    let body = JSON.stringify({token,password});
    return fetch(`${endpoint}/change-password`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

/*************************** Stripe *******************************/

export function setupStripePaymentIntentAPI(){
    return fetch(`${endpoint}/payment/stripe/setup-payment-intent`,{...fetch_options,method:'POST'}, ).then(r=>r.json())
}
export function deleteStripeCardAPI(pm_id){
    return fetch(`${endpoint}/payment/stripe/cards/${pm_id}`,{...fetch_options,method:'DELETE'}, ).then(r=>r.json())
}

export function unsubscribe(subscription_id){
    return fetch(`${endpoint}/payment/stripe/${subscription_id}`,{...fetch_options,method:'DELETE'}, ).then(r=>r.json())
}

export function createStripePaymentIntentAPI(data){
    return fetch(`${endpoint}/payment/stripe/create-payment-intent`, {...fetch_options, body:JSON.stringify(data),method:'POST'}).then(r=>r.json())
}
export function createStripeSubscriptionAPI(data){
    return fetch(`${endpoint}/payment/stripe/create-subscription`, {...fetch_options, body:JSON.stringify(data),method:'POST'}).then(r=>r.json())
}

export function pay(data){
    return fetch(`${endpoint}/payment`, {...fetch_options, body:JSON.stringify(data),method:'POST'}).then(r=>r.json())
}
export function payWithCardAPI(cardId, data){
    return fetch(`${endpoint}/payment/stripe/buy-with-saved-card/${cardId}`, {...fetch_options, body:JSON.stringify(data), method:'POST'}).then(r=>r.json())
}

/*************************** Paypal *******************************/

export function pendingPaypalAPI(data){
    return fetch(`${endpoint}/payment/paypal/pending`, {...fetch_options, body:JSON.stringify(data),method:'POST'}).then(r=>r.json())
}
// ***************************************************************CRYPTO
export function pendingCryptoAPI(data){
    return fetch(`${endpoint}/payment/crypto/pending`, {...fetch_options, body:JSON.stringify(data),method:'POST'}).then(r=>r.json())
}

export function turnOnOff2faAPI(data) {
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/account/2fa`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export const STRIPE_WEB_HOOK = `${endpoint}/payment/webhook/stripe`;